import React, { useState } from "react";
import "./inscricoes.css";
import BG from "../../assets/img/inscricoes/bg2.svg";
import CardPadrao from "./cardPadrao/CardPadrao";
import CardPersonalizado from "./cardPersonalizado/CardPersonalizado";
import CardCombo from "./cardCombo/CardCombo";

const Tab = ({ label, activeTab, onClick }) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <div
      className={`tab ${activeTab === label ? "active" : ""}`}
      onClick={handleClick}
    >
      {label}
    </div>
  );
};

const TabContent = ({ activeTab }) => {
  return (
    <div className="tab-content">
      <div className="cards-inscricoes">
        <CardPadrao activeTab={activeTab} />
        <CardPersonalizado activeTab={activeTab} />
        <div className="separador">
          <p>+</p>
        </div>
        <CardCombo activeTab={activeTab} />
      </div>
    </div>
  );
};

const Inscricoes = () => {
  const [activeTab, setActiveTab] = useState("Escolas Públicas");

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <section id="inscricoes">
      <div className="container inscricoes-container">
        <div className="inscricoes-bg">
          <img src={BG} alt="" />
        </div>
        <div className="inscricoes-header">
          <h4>Inscrições</h4>
        </div>

        <div className="tabs">
          <Tab
            label="Escolas Públicas"
            activeTab={activeTab}
            onClick={handleTabClick}
            id="publicas"
          />
          <Tab
            label="Escolas Privadas"
            activeTab={activeTab}
            onClick={handleTabClick}
            id="privadas"
          />
        </div>

        <div className="inscricoes-txt">
          {activeTab === "Escolas Públicas" && (
            <p>Valores para Escolas Públicas</p>
          )}
          {activeTab === "Escolas Privadas" && (
            <p>Valores para Escolas Privadas</p>
          )}
        </div>
        <TabContent activeTab={activeTab} />
      </div>
    </section>
  );
};

export default Inscricoes;
