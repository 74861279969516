import React from "react";
import "./regulamento.css";
import { NavLink } from "react-router-dom";

const Regulamento = () => {
  return (
    <div className="regulamento-txt">
      <ul id="regulamento">
        <li id="natureza">
          <p>1. Natureza.</p>
          <small>
            1.1. O Concurso Canguru de Matemática Brasil é uma ação educacional
            que conta com a participação de Escolas públicas e privadas de
            Educação Básica em território nacional.
          </small>
          <br />
          <br />
          <small>
            1.2. Realização
            <br />O Concurso é uma realização da UpMat Educacional, em parceria
            com a Associação Kangourou Sans Frontières AKSF, responsável pela
            iniciativa Canguru de Matemática a nível mundial.
          </small>
        </li>
        <li id="participacao">
          <p>2. Participação.</p>
          <small>
            2.1. Podem participar do Concurso Canguru de Matemática Brasil todos
            os estudantes de escolas públicas e privadas brasileiras,
            matriculados regularmente do 3º ano do Ensino Fundamental até o 3º
            ano do Ensino Médio, por meio de suas escolas, responsáveis por
            realizar a inscrição para o Concurso.
          </small>
          <br />
          <br />
          <small>
            2.2. Os estudantes da modalidade de ensino denominada Educação de
            Jovens e Adultos (EJA) também podem participar do Concurso e serão
            distribuídos conforme as regras previstas no item 5 deste
            Regulamento.
          </small>
          <br />
          <br />
          <small>
            2.3. É vedada a participação de estudantes de escolas preparatórias
            para vestibulares e de cursos livres. A participação é restrita aos
            estudantes matriculados em escolas de Educação Básica devidamente
            cadastradas e inscritas no MEC/INEP.
          </small>
          <br />
          <br />
          <small>
            2.4. Os participantes do Concurso serão divididos em 6 (seis) níveis
            de prova, conforme discriminado no item 6 deste regulamento.
          </small>
        </li>
        <li id="objetivos">
          <p>3. Objetivos.</p>
          <small>
            3.1. São objetivos do Concurso Canguru de Matemática Brasil:
          </small>
          <ul id="list">
            <li>
              <small>
                &bull; Ampliar e incentivar a aquisição dos conhecimentos
                matemáticos.
              </small>
              <br />
            </li>
            <li>
              <small>
                &bull; Contribuir para a melhoria do ensino e aprendizagem da
                Matemática do Ensino Fundamental ao Ensino Médio.
              </small>
              <br />
            </li>
            <li>
              <small>
                &bull; Favorecer o estudo de maneira interessante e
                contextualizada, aproximando os estudantes do universo da
                Matemática.
              </small>
              <br />
            </li>
            <li>
              <small>
                &bull; Promover nos estudantes a realização e a satisfação
                através da atividade intelectual.
              </small>
              <br />
            </li>
          </ul>
        </li>
        <li id="inscricao">
          <p>4. Inscrição.</p>
          <small>
            4.1. A escola e seus representantes, antes de realizarem a sua
            inscrição, deverão ler atentamente este Regulamento para se
            certificar de que aceitam todas as condições nele estabelecidas e de
            que preenchem todos os requisitos exigidos para a participação no
            Concurso.
          </small>
          <br />
          <br />
          <small>
            4.2. A inscrição é feita pela escola, que utilizará seu código do
            MEC/INEP de 8 dígitos para identificação durante todo o processo. No
            caso de escolas com mais de uma unidade escolar, cada unidade da
            rede ou do grupo de escolas deve ser inscrita com o seu próprio
            código MEC/INEP.
          </small>
          <br />
          <br />
          <small>
            4.3. Os professores, diretores e coordenadores serão os responsáveis
            por inscrever as escolas, fazer download e imprimir os arquivos de
            provas (aplicação impressa) ou distribuir logins e senhas para os
            estudantes (aplicação online), aplicar as provas nos termos deste
            Regulamento, enviar as respostas dos estudantes para a organização
            do Concurso Canguru e cumprir com todos os procedimentos previstos
            para participação no Concurso. Ao realizar a inscrição, será
            solicitado que a escola indique apenas um Responsável pela escola no
            Concurso e as comunicações da Organização do Concurso serão feitas
            diretamente com esse Responsável.
          </small>
          <br />
          <br />
          <small>
            4.4. As escolas privadas conveniadas com a rede pública de ensino
            que tiverem composição mista (estudantes da rede pública e privada)
            serão consideradas como redes privadas em todas as etapas do
            processo.
          </small>
          <br />
          <br />
          <small>
            4.5. No site{" "}
            <a
              href="https://www.cangurudematematicabrasil.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.cangurudematematicabrasil.com.br/
            </a>
            , apenas a escola pode fazer inscrição no Concurso e indicar os
            estudantes daquela escola que aceitaram participar da prova. Dessa
            forma, não são permitidas inscrições de pessoas físicas. Isto é, um
            estudante (ou um grupo de estudantes), pais e familiares não podem
            fazer inscrições para o Canguru sem a intermediação das escolas.
          </small>
          <br />
          <br />
          <small>
            4.6. O período de inscrições e pagamentos será estritamente
            considerado o período disponível no Calendário Oficial do Concurso.
            Não serão aceitas inscrições nem pagamentos fora desse período sob
            qualquer hipótese.
          </small>
          <br />
          <br />
          <small>
            4.7. Durante o período de inscrições, o Responsável de cada escola
            (descrito no item 4.3) deve inserir os dados solicitados na seção
            “Inscrições” do site e seguir as instruções para o cadastro e o
            pagamento da taxa de inscrição.
          </small>
          <br />
          <br />
          <small>
            4.8. Não serão aceitas inscrições enviadas por meio de mensagens
            eletrônicas, fax, telefone ou postal.
          </small>
          <br />
          <br />
          <small>
            4.9. A inscrição será efetivada após o pagamento da taxa por parte
            de cada escola, que terá o valor definido de acordo com o pacote
            escolhido (Padrão, Personalizado e Concurso + Experience). O
            detalhamento de cada um dos pacotes e seus valores encontram-se no
            ANEXO I deste Regulamento.
          </small>
          <br />
          <br />
          <small>
            4.9.1. Os valores são por escola, independentemente do número de
            estudantes participantes do Concurso.
          </small>
          <br />
          <br />
          <small>
            4.9.2. Cada unidade escolar (identificada pelo código MEC/INEP)
            deverá efetuar sua inscrição e o pagamento correspondente. As
            escolas com mais de uma unidade escolar (grupos e redes de escolas)
            deverão realizar o pagamento por código MEC/INEP e não por grupo ou
            rede, conforme previsto no item 4.2.
          </small>
          <br />
          <br />
          <small>
            4.10. O pagamento da taxa de inscrição poderá ser efetuado por meio
            de boleto bancário. O pagamento do boleto poderá ser efetuado em
            qualquer agência bancária, casa lotérica ou agência dos Correios,
            seguindo os critérios estabelecidos nesses correspondentes
            bancários. O prazo de vencimento do boleto é determinado
            automaticamente pela plataforma do Concurso e não poderá sofrer
            alterações. No entanto, escolas inscritas no Concurso que precisarem
            de um novo boleto com uma nova data de vencimento poderão
            solicitá-lo, desde que esteja vigente o período de inscrições para o
            Concurso.
          </small>
          <br />
          <br />
          <small>
            4.11. A inscrição da escola só será confirmada mediante a
            confirmação do pagamento dentro do prazo estabelecido. No caso do
            boleto bancário, após o processamento pelo sistema bancário e a
            confirmação do pagamento do boleto dentro do prazo estabelecido.
          </small>
          <br />
          <br />
          <small>
            4.12. Não serão aceitas inscrições de escolas cujo pagamento da taxa
            tenha sido efetuado fora do prazo. No caso do pagamento em boleto, o
            prazo é determinado no próprio boleto.
          </small>
          <br />
          <br />
          <small>
            4.13. A organização do Concurso não se responsabilizará por
            solicitações de inscrições não recebidas por motivos de ordem
            técnica dos computadores, falhas de comunicação, congestionamento
            das linhas de comunicação, bem como outros fatores que
            impossibilitem a transferência de dados.
          </small>
          <br />
          <br />
          <small>
            4.14. As Notas Fiscais de Serviços referentes aos pagamentos das
            inscrições só serão disponibilizadas, no mínimo, 15 dias após a
            confirmação de pagamento pelo banco. Não emitimos, em hipótese
            alguma, Notas Fiscais prévias à confirmação do pagamento pela nossa
            instituição bancária.
          </small>
          <br />
          <br />
          <small>
            4.15. A escola, ao inscrever-se no Concurso Canguru de Matemática
            Brasil, concorda integralmente com todas as condições e regras
            previstas no presente regulamento.
          </small>
          <br />
          <br />
          <small>
            4.16. O valor referente à taxa de inscrição da escola será
            reembolsado apenas em caso de cancelamento do Concurso pelo Canguru
            de Matemática Brasil.
          </small>
          <br />
          <br />
          <small>
            4.17. Durante a inscrição da escola no Concurso, o(s)
            Responsável(is) pela aplicação do Canguru na escola poderá(ão)
            cadastrar login(s) e senha(s) para acesso à área reservada. Após a
            confirmação do pagamento da taxa de inscrição, essas informações
            serão enviadas para cada e-mail cadastrado durante a inscrição,
            possibilitando o acesso à área reservada. Somente com esse login e
            essa senha poderão ser acessadas as páginas contendo as provas e
            folhas de respostas para posterior reprodução.
          </small>
          <br />
          <br />
          <small>
            4.18. A escola e seu Representante são responsáveis por informar
            corretamente todos os dados da escola e do Representante no ato da
            inscrição, especialmente endereço e contatos, inclusive e-mails e
            números de telefone. A organização do Concurso não se
            responsabilizará por qualquer problema na participação das escolas
            decorrente de informações equivocadas ou incompletas. O tratamento
            dos dados pessoais ocorrerá nos termos definidos na Lei nº.
            13.709/2018 (Lei Geral de Proteção de Dados Pessoais) e serão
            utilizados exclusivamente para os fins deste Concurso (conforme
            descrito no item 5.8).
          </small>
        </li>
        <li id="cadastramento">
          <p>5. Cadastramento completo da escola e dos estudantes.</p>
          <small>
            5.1. Após a confirmação do pagamento da inscrição, o Responsável
            pela escola receberá no e-mail fornecido durante a inscrição, a
            confirmação do seu login e da sua senha para acesso à área reservada
            da plataforma. A partir deste momento, ele será considerado, para
            todos os efeitos deste regulamento, o Responsável pelo Concurso
            Canguru na Escola. Juntamente com o login e a senha, o Responsável
            pelo Canguru na Escola receberá instruções para acessar a área
            reservada da plataforma, onde encontrará Manuais de Instruções com
            orientações detalhadas para cada uma das etapas do Concurso.
          </small>
          <br />
          <br />
          <small>
            5.2. Com o login e senha para o acesso à área reservada, o
            Representante da escola poderá escolher o formato da aplicação das
            provas do Concurso (impressa ou online) e cadastrar os estudantes
            que desejarem realizar a prova informando nome completo e ano
            escolar (série) que cada estudante está cursando na escola. Este
            cadastramento pode ser realizado antes ou, no caso da aplicação
            impressa, após a aplicação das provas, conforme Calendário Oficial,
            para possibilitar que eventuais estudantes não cadastrados
            previamente e que decidam fazer a prova, possam realizá-la.
          </small>
          <br />
          <br />
          <small>
            5.3. O cadastramento dos estudantes poderá ser realizado ou alterado
            conforme prazo previsto no Calendário Oficial.
          </small>
          <br />
          <br />
          <small>
            5.4. Até a data prevista em Calendário Oficial, será necessário
            enviar, via nossa plataforma, uma planilha Excel com os nomes
            completos e os níveis de prova que os estudantes realizarão.
            Disponibilizamos um modelo de planilha para download na área
            reservada, e o arquivo deverá contemplar esse modelo. Não serão
            aceitos outros formatos de envio.
          </small>
          <br />
          <br />
          <small>
            5.5. Se a escola tiver dúvidas sobre quais estudantes farão a prova,
            aconselhamos que TODOS sejam cadastrados. Caso algum estudante
            cadastrado não realize a prova, a escola não será prejudicada.
          </small>
          <br />
          <br />
          <small>
            5.6. No caso da aplicação online, realizada dentro do ambiente
            escolar, a organização do Concurso atribuirá um usuário (login) e
            uma senha para cada estudante cadastrado, fornecendo esses dados à
            escola. A escola ficará responsável por distribuir esses logins e
            senhas aos seus estudantes. Para evitar problemas no dia de
            aplicação das provas, a escola deverá certificar-se de que todos os
            estudantes receberam seus logins e senhas e poderão acessar a
            plataforma no dia combinado. A Organização do Concurso não se
            responsabiliza caso algum estudante não receba essas informações e
            não consiga realizar a prova adequadamente.
          </small>
          <br />
          <br />
          <small>
            5.7. O tratamento dos dados pessoais dos participantes do Concurso,
            representantes das escolas, professores, coordenadores, diretores e
            demais envolvidos na organização da iniciativa ocorrerá nos termos
            definidos na Lei nº. 13.709/2018 (Lei Geral de Proteção de Dados
            Pessoais), sendo que os dados pessoais tratados serão dados
            cadastrais das escolas, dos responsáveis pela escola, dos
            Professores, nomes e anos escolares (séries) dos participantes, para
            as finalidades de organização e participação no Concurso, ficando as
            escolas responsáveis por obter as autorizações dos responsáveis
            legais dos estudantes nos casos eventualmente necessários.Os dados
            pessoais dos participantes serão tratados pelos Organizadores do
            Concurso e serão armazenados pelo prazo necessário ao cumprimento de
            todas as providências necessárias ao atingimento dos propósitos do
            objeto do presente Regulamento. As escolas inscritas e seus
            representantes autorizam e estão de acordo em receber comunicações
            por parte dos Organizadores do Canguru.
          </small>
        </li>
        <li id="provas">
          <p>6. Provas</p>
          <small>
            6.1. As questões das provas são objetivas (testes) com cinco
            alternativas cada, sendo apenas uma alternativa correta por questão.
          </small>
          <br />
          <br />
          <small>
            6.2. Há seis níveis de provas:
            <br />
            <b>Nível P (Pre Ecolier)</b> – estudantes do 3º e 4º anos do EFI.
            <br />
            <b>Nível E (Ecolier)</b> – estudantes do 5º e 6º anos do EFI e EFII,
            respectivamente.
            <br />
            <b>Nível B (Benjamin)</b> – estudantes do 7º e 8º anos do EFII.
            <br />
            <b>Nível C (Cadet)</b> – estudantes do 9º ano do EFII.
            <br />
            <b>Nível J (Junior)</b> – estudantes dos 1º e 2º anos do EM.
            <br />
            <b>Nível S (Student)</b> – estudantes da 3º ano do EM.
          </small>
          <br />
          <br />
          <small>
            6.3. Números de questões: as provas dos níveis P e E têm 24 questões
            cada e as dos demais níveis, 30 questões cada.
          </small>
          <br />
          <br />
          <small>6.4. Duração máxima: 1h 40 min para todos os níveis.</small>
          <br />
          <br />
          <small>
            6.5. Idiomas: As provas são oferecidas nos idiomas Português e
            Inglês. A escola pode optar por qualquer um dos idiomas para
            aplicação. Nas duas versões, as questões apresentam as mesmas
            informações para a resolução, graus de dificuldade, alternativas e
            respostas corretas.
          </small>
          <br />
          <br />
          <small>
            6.6. Cores: As provas são oferecidas nas versões Colorida e Preto e
            Branco para a aplicação impressa. Reforçamos que não há vantagens ou
            prejuízos de um tipo de impressão com relação ao outro. Nos dois
            tipos de impressão, as questões são passíveis de solução e
            apresentam as mesmas informações para resolução, graus de
            dificuldade, alternativas e respostas corretas.
          </small>
          <br />
          <br />
          <small>
            6.7. Estrutura das provas:
            <br />
            As provas são compostas por questões organizadas em três graus de
            dificuldade: fácil, médio e difícil. A distribuição das questões
            varia de acordo com o nível da prova:
            <br />
            <ul id="list-dificuldade">
              <b>&bull; Níveis P e E:</b>
              <li>
                &bull; <b>Questões 1 a 8:</b> Grau fácil
              </li>
              <li>
                &bull; <b>Questões 9 a 16:</b> Grau médio
              </li>
              <li>
                &bull; <b>Questões 17 a 24:</b> Grau difícil
              </li>
            </ul>
            <br />
            <ul id="list-dificuldade">
              <b>&bull; Níveis P e E:</b>
              <li>
                &bull; <b>Questões 1 a 10:</b> Grau fácil
              </li>
              <li>
                &bull; <b>Questões 11 a 20:</b> Grau médio
              </li>
              <li>
                &bull; <b>Questões 21 a 30:</b> Grau difícil
              </li>
            </ul>
            <br />
            Além da dificuldade, o conteúdo também varia conforme o nível:
            <ul id="list-dificuldade">
              <li>
                &bull; <b>Níveis Elementares (P, E, B, C):</b> O foco é nas
                habilidades de raciocínio.
              </li>
              <li>
                &bull; <b>Níveis J e S:</b> Exigem o conhecimento técnico
                adicional.
              </li>
            </ul>
          </small>
          <br />
          <br />
          <small>
            6.8. Pontuação:
            <br />A pontuação das provas é distribuída de acordo com o nível e a
            dificuldade das questões, conforme detalhado abaixo:
            <ul id="list-dificuldade">
              <b>&bull; Níveis P e E:</b>
              <li>
                &bull; <b>Questões 1 a 8:</b> 3 pontos cada
              </li>
              <li>
                &bull; <b>Questões 9 a 16:</b> 4 pontos cada
              </li>
              <li>
                &bull; <b>Questões 17 a 24:</b> 5 pontos cada
              </li>
              <li>
                &bull; <b>Total de pontos:</b> 96 pontos nas 24 questões.
              </li>
            </ul>
            <br />
            <ul id="list-dificuldade">
              <b>&bull; Níveis P e E:</b>
              <li>
                &bull; <b>Questões 1 a 10:</b> 3 pontos cada
              </li>
              <li>
                &bull; <b>Questões 11 a 20:</b> 4 pontos cada
              </li>
              <li>
                &bull; <b>Questões 21 a 30:</b> 5 pontos cada
              </li>
              <li>
                &bull; <b>Total de pontos:</b> 120 pontos nas 30 questões.
              </li>
            </ul>
          </small>
          <br />
          <br />
          <small>
            6.8.1. Respostas erradas, rasuradas ou marcadas com mais de uma
            alternativa são penalizadas. É possível evitar a penalização,
            conforme instrução descrita no item 6.9. Para o cálculo final da
            nota, somam-se pontos para eliminar notas negativas, conforme item
            6.9 a seguir.
          </small>
          <br />
          <br />
          <small>
            6.9. Cálculo da pontuação final:
            <br />
            <ul id="list-dificuldade">
              <li>
                &bull; Respostas erradas, rasuradas ou marcadas com mais de uma
                alternativa por questão na folha de respostas anulam a questão,
                além de acarretar um desconto de 25% do valor da questão.
              </li>
              <li>
                &bull; Questões não respondidas valem 0 (zero) ponto. O
                estudante pode deixar a questão em branco, se não tiver certeza
                da resposta.
              </li>
              <li>
                &bull; O total de pontos é igual à soma dos pontos obtidos nas
                questões das provas de acordo com as regras acima, mais 24
                pontos
                <br />
                (níveis P e E) ou 30 pontos (demais níveis). O acréscimo desses
                pontos ocorre para se evitar notas negativas no Concurso.
              </li>
            </ul>
          </small>
          <br />
          <small>
            6.10. Conteúdo Programático:
            <br />
            <ul id="list-dificuldade">
              <li>
                &bull;{" "}
                <NavLink to={"/conteudo-programatico"}>
                  <b>Clique aqui</b>
                </NavLink>{" "}
                e acesse o conteúdo Programático completo.
              </li>
            </ul>
          </small>
        </li>
        <li id="aplicacao-prova">
          <p>7. Recepção e aplicação da prova</p>
          <small>
            7.1. As provas do Concurso Canguru de Matemática Brasil são
            individuais e cada estudante deverá resolver as questões e assinalar
            a alternativa correspondente em seu gabarito. As provas serão
            realizadas na própria escola, que será a responsável por sua
            aplicação de acordo com as instruções disponibilizadas no site e nos
            Manuais disponíveis na área reservada, que o Responsável pelo
            Concurso Canguru na Escola deve acessar usando login e senha.
          </small>
          <br />
          <br />
          <small>
            7.1.1. É proibido consultar outras pessoas, livros, anotações ou
            qualquer outro material durante a prova, inclusive calculadoras. É
            proibido fazer uso de aparelhos eletrônicos e consultas na internet
            para gerar benefício próprio ou para terceiros na prova, ficando a
            cargo do participante e do Responsável o compromisso com a ética e
            responsabilidade pelos seus atos. Qualquer irregularidade verificada
            durante a aplicação da prova, na transcrição ou no envio dos
            resultados poderá ser punida com a exclusão da escola desta edição
            do Concurso, além da impossibilidade de participação nas próximas
            edições por tempo indeterminado.
          </small>
          <br />
          <br />
          <small>
            7.1.2. As provas podem ser realizadas de forma impressa ou online.
            Na aplicação impressa, as escolas são responsáveis por fazer o
            download das provas e folhas de respostas, imprimí-las e
            distribuí-las para todos os estudantes participantes. Na aplicação
            online, as escolas são responsáveis por distribuir logins e senhas
            para todos os estudantes participantes e se certificar de que todos
            conseguiram acessar a plataforma do estudante para a realização das
            provas nas datas e horários escolhidos, de acordo com o Calendário
            Oficial.
          </small>
          <br />
          <br />
          <small>
            7.2. Formatos de Aplicação das Provas
            <br />
            As provas podem ser realizadas presencialmente nas escolas de duas
            formas: <b>impressa</b> ou <b>online</b>. Cada formato exige
            procedimentos específicos que devem ser seguidos pela escola para
            assegurar a correta aplicação das provas.
            <br />
            <br />
            <ul id="list-dificuldade">
              &bull; <b>Aplicação Impressa:</b>
              <li>
                &bull; A escola é responsável por fazer o download das provas e
                das folhas de respostas, imprimi-las e distribuí-las para todos
                os estudantes participantes.
              </li>
              <li>
                &bull; As provas estarão na “Área da Escola”, ambiente exclusivo
                para as escolas disponível no site
                www.cangurudematematicabrasil.com.br, conforme as datas
                previstas no Calendário Oficial.
              </li>
              <li>
                &bull; As folhas de respostas, uma para cada estudante de acordo
                com o seu nível (P, E, B, C, J ou S), também deverão ser
                impressas e distribuídas.
              </li>
              <li>
                &bull; Em caso de estudantes neuro atípicos ou com necessidades
                especiais, autorizamos as escolas a fazerem as adaptações
                necessárias para a correta aplicação das provas.
              </li>
            </ul>
            <br />
            Formatos de Download: As provas estarão disponíveis em PDF, nas
            versões colorida e preto e branco, em Português e Inglês, permitindo
            que a escola escolha a melhor opção de impressão sem prejuízo na
            solução das questões.
            <br />
            <br />
            <ul id="list-dificuldade">
              &bull;{" "}
              <b>Aplicação Online - NOVO PROCEDIMENTO DE APLICAÇÃO ONLINE:</b>
              <br />
              <br />
              <b>Informação de Datas:</b> As escolas que optarem pela aplicação
              online devem informar, na "Área da Escola", os dias selecionados
              para aplicação, podendo escolher até dois dias dentro do período
              de realização da prova.
              <br />
              <br />
              <li>
                &bull; A escola é responsável por distribuir logins e senhas
                para os estudantes, garantindo que todos consigam acessar a
                plataforma do estudante (disponível no site do Concurso Canguru)
                para a realização das provas nas datas e horários escolhidos, em
                conformidade com o Calendário Oficial.
              </li>
              <li>
                &bull; As provas estarão disponíveis na plataforma da
                organização nos dias programados no Calendário Oficial, e os
                estudantes deverão realizá-las nas instalações da escola. Em
                caso de aplicação fora do ambiente escolar, a escola poderá ser
                desclassificada.
              </li>
              <li>
                &bull; Em caso de aplicação fora do ambiente escolar, a escola
                poderá ser desclassificada.
              </li>
              <li>
                &bull; As provas online ficarão disponíveis das 07h00 até às
                23h59, de acordo com os dias escolhidos pela escola.
              </li>
              <li>
                &bull; Para realizar as provas, os estudantes acessarão a
                plataforma do Estudante e realizarão a prova diretamente na
                plataforma.
              </li>
            </ul>
          </small>
          <br />
          <br />
          <small>
            7.2.1. É requerido que o Responsável pelo Concurso na Escola
            mantenha as provas em sigilo até a data de sua aplicação,
            esclarecendo desde já que qualquer irregularidade identificada no
            cumprimento desse dever, sujeita a escola e seus estudantes ao
            previsto no item 7.7.
          </small>
          <br />
          <br />
          <small>
            7.3. As provas do Concurso Canguru serão aplicadas conforme
            Calendário Oficial, em horário que convenha à escola. O período de
            aplicação das provas compreende a data oficial e as datas
            alternativas. A Organização do Concurso recomenda que seja utilizada
            a data oficial para a aplicação das provas, mas autoriza a aplicação
            em datas alternativas, caso a escola não possa realizar as provas na
            data oficial. A escola deverá escolher uma dessas datas alternativas
            e realizar todas as provas no mesmo dia. É expressamente proibida a
            realização da prova ANTES da data oficial de aplicação. de aula.
          </small>
          <br />
          <br />
          <small>
            7.4. Os cadernos de questões e as folhas de respostas devem ser
            recolhidos após a aplicação das provas e armazenados pela escola.
            Após a autorização do Comitê do Concurso e o decurso do prazo
            previsto na cláusula 7.6 abaixo, os cadernos de questões poderão ser
            devolvidos aos estudantes e trabalhados em sala de aula. As folhas
            de respostas preenchidas pelos estudantes deverão ser armazenadas em
            local seguro na escola para possível verificação posterior por parte
            dos organizadores do Concurso e para cumprimento da cláusula 9.2
            abaixo.
          </small>
          <br />
          <br />
          <small>
            7.5. Os gabaritos (alternativas corretas) serão divulgados somente
            depois de encerrado o prazo de envio das respostas pelas escolas.
          </small>
          <br />
          <br />
          <small>
            7.6. É expressamente proibida a divulgação das provas e de quaisquer
            de suas questões pela escola fora do âmbito escolar (via internet ou
            outros meios) até 60 dias após a data oficial de aplicação. Essa
            regra faz parte do acordo internacional da AKSF (Associação Canguru
            Sem Fronteiras), pois há países que irão aplicar a prova após o dia
            oficial. A violação dessa regra pode causar o desligamento do país
            onde o fato ocorrer. Solicitamos especial cuidado com as fotos e
            vídeos realizados no dia de aplicação para que as questões não
            fiquem visíveis e legíveis.
          </small>
          <br />
          <br />
          <small>
            7.7. Qualquer irregularidade verificada antes, durante e após a
            aplicação das provas do Concurso Canguru como por exemplo, a
            transcrição ou envio dos resultados poderá ser punida com a exclusão
            da escola desta edição do Concurso, além de a impossibilidade de
            participação nas próximas edições, por tempo indeterminado, além da
            apuração de eventuais prejuízos ou outras medidas legais cabíveis.
          </small>
        </li>
        <li id="envio-respostas">
          <p>8. Envio das respostas dos estudantes e correção das provas.</p>
          <small>
            8.1. O envio das respostas dos estudantes acontece de maneiras
            diferentes nas aplicações impressa e online. faltando.
          </small>
          <br />
          <br />
          <small>
            8.1.1. Na aplicação impressa, após a aplicação das provas, é de
            responsabilidade da escola o envio via plataforma das respostas
            dadas pelos seus estudantes, no prazo previsto pelo Calendário
            Oficial. Neste momento, deverão ser inseridos eventuais estudantes
            que ainda não constem no sistema e que tenham realizado a prova, bem
            como suas respostas. Após esta inserção, aconselha-se uma avaliação
            cautelosa da listagem de estudantes para verificar se não há
            estudantes faltando.
          </small>
          <br />
          <br />
          <small>
            8.1.2. Na aplicação online, é de responsabilidade do estudante o
            registro e o envio das respostas enquanto realiza a prova na
            plataforma do Estudante, a qual acessa com login e senha gerados
            pela organização do Concurso e distribuídos pela escola.
          </small>
          <br />
          <br />
          <small>
            8.2. Na aplicação impressa, o envio das respostas pode ser feito de
            duas maneiras:
          </small>
          <br />
          <br />
          <small>
            8.2.1. Por meio de planilha Excel, conforme modelo disponibilizado
            no site, ou de acordo com as instruções disponíveis em Manual
            próprio.
          </small>
          <br />
          <br />
          <small>
            8.2.2. Utilização do aplicativo web responsivo do Concurso Canguru
            para captura das respostas dos estudantes diretamente na folha de
            respostas. O aplicativo é acessado pelo mesmo link da área da escola
            e deve ser utilizado via navegador no celular.
          </small>
          <br />
          <br />
          <small>
            8.2.3. O Responsável pelo Canguru na escola deverá acessar o site do
            Concurso, onde encontrará as instruções para o envio das respostas.
          </small>
          <br />
          <br />
          <small>
            8.3. Na aplicação online, os estudantes registram as suas respostas
            na plataforma disponível na Área do Estudante durante a realização
            das provas. A tela para o registro das respostas é de uso intuitivo
            e simples. Cada estudante deverá inserir a alternativa (letra) que
            marcou como resposta para cada questão e finalizar o procedimento.
            Após a finalização, os estudantes não poderão alterar as respostas
            registradas. Essa etapa do processo também é explicada pela
            Organização do Concurso em um tutorial disponível na área reservada.
          </small>
          <br />
          <br />
          <small>
            8.4. Por motivos organizacionais, não será permitida a retificação
            de dados após a data limite para envio das respostas e antes da
            liberação dos resultados preliminares. Solicita-se aos Responsáveis
            a revisão dos dados antes da data limite. Depois disto, não será
            possível fazer modificações até que sejam divulgados os resultados
            preliminares (conforme item 9.3).
          </small>
          <br />
          <br />
          <small>
            8.5. Os dados enviados serão utilizados para a análise dos
            resultados em âmbito nacional, mas não serão divulgados
            publicamente.
          </small>
        </li>
        <li id="resultados">
          <p>9. Resultados e premiações</p>
          <small>
            9.1. A plataforma do Concurso Canguru de Matemática Brasil fará a
            correção das respostas enviadas e fornecerá, às escolas
            participantes, os resultados preliminares de seus respectivos
            estudantes divididos nas categorias:
            <br /> I) estudantes com potencial de premiação e <br />
            II) estudantes participantes. Os estudantes com potencial de
            premiação serão divididos nas quatro categorias abaixo:
            <br />
            &bull; 1% dos melhores colocados, por nível, em território nacional,
            receberão a classificação <b>ouro</b>;
            <br />
            &bull; 2% dos melhores colocados, por nível, em território nacional,
            receberão a classificação <b>prata</b>
            ;
            <br />
            &bull; 3% dos melhores colocados, por nível, em território nacional,
            receberão a classificação <b>bronze</b>, e;
            <br />
            &bull; 4% dos melhores colocados, por nível, em território nacional,
            receberão a classificação <b>honra ao mérito</b>;
          </small>
          <br />
          <br />
          <small>
            9.1.1. Os resultados preliminares não devem ser divulgados aos
            estudantes e aos familiares, pois podem sofrer alterações após as
            retificações necessárias. A divulgação aos estudantes e familiares
            só poderá ocorrer após o resultado oficial.
          </small>
          <br />
          <br />
          <small>
            9.1.2. Não há distinção entre estudantes que realizam as provas
            impressas ou online para fins de premiação. A nota de corte é
            calculada com base na participação de todos os estudantes em
            território nacional, independentemente da forma de aplicação das
            provas.
          </small>
          <br />
          <br />
          <small>
            9.2. Escolas com estudantes com potencial de premiação poderão
            receber um aviso para que enviem à organização do Concurso as
            imagens escaneadas das folhas de respostas originais desses
            estudantes para a devida autenticação.
            <br />
            <b>Atenção!</b> Caso solicitado, o envio das folhas de respostas dos
            estudantes com potencial de premiação será realizado através de
            formulário online específico, o qual disponibilizamos na área
            reservada da plataforma do Concurso Canguru. Este formulário deverá
            ser preenchido e entregue dentro das datas previstas no Calendário
            Oficial. O não envio das folhas de respostas dos estudantes com
            possibilidade de premiação impedirá que a escola receba medalhas e
            os respectivos Certificados de premiação para seus estudantes
            premiados.
          </small>
          <br />
          <br />
          <small>
            9.3. Após a divulgação dos resultados preliminares, a escola terá o
            prazo de 10 dias corridos para apresentação de recursos em relação
            aos resultados preliminares, retificação de nomes, verificação de
            inclusão de todos os estudantes e demais informações que a escola
            houver inserido na plataforma Canguru durante o Concurso. Após o
            decurso deste prazo, não será mais possível a retificação dos dados.
          </small>
          <br />
          <br />
          <small>
            9.4. A Organização do Concurso Canguru de Matemática Brasil
            divulgará somente para as escolas os resultados finais, contendo os
            nomes dos estudantes premiados e as notas de todos os estudantes
            participantes, conforme Calendário Oficial. As escolas terão acesso
            apenas aos resultados de seus próprios estudantes.
          </small>
          <br />
          <br />
          <small>
            9.4.1. Caberá às escolas divulgarem os resultados para seus
            estudantes e familiares da maneira que entenderem conveniente e de
            acordo com o seu calendário de atividades.
          </small>
          <br />
          <br />
          <small>
            9.5. Não haverá ranking de escolas. Cada escola terá acesso apenas
            aos resultados de seus estudantes. Serão divulgadas apenas as notas
            de corte das premiações e dados estatísticos gerais ou relativos aos
            estudantes da escola, com maiores detalhes, de acordo com o pacote
            selecionado (ANEXO I).
          </small>
        </li>
        <li id="medalhas">
          <p>10. Medalhas e certificados</p>
          <small>
            10.1. Após a divulgação dos resultados finais, de acordo com o
            Calendário Oficial disponível no site oficial do Concurso Canguru de
            Matemática Brasil, as escolas que desejarem poderão realizar a
            compra das medalhas de premiação para seus estudantes. A compra de
            medalhas deverá ser realizada exclusivamente pela escola e não
            poderá ser realizada por estudantes e/ou familiares. O Representante
            do Canguru na escola deverá acessar a área reservada do site e
            realizar o seu pedido. Neste momento, ele deverá preencher os dados
            da escola e os dados de envio do pacote de medalhas.
          </small>
          <br />
          <br />
          <small>
            10.2. A escola é responsável por informar corretamente todos os
            dados de endereço para envio e contatos de e-mail e  telefone no ato
            do pedido. A organização do Concurso Canguru de Matemática Brasil
            não se responsabilizará pela não entrega das medalhas em caso de
            endereços e informações erradas ou incompletas. Caso seja necessário
            fazer um reenvio, a escola deverá arcar com o custo de frete para
            esse novo envio. Portanto, pedimos que atentem para a precisão das
            informações de endereço.
          </small>
          <br />
          <br />
          <small>
            10.3. Só será permitida a compra do pacote total de medalhas de
            premiação (ouro, prata, bronze e honra ao mérito).
          </small>
          <br />
          <br />
          <small>
            10.4. O pagamento do pedido de medalhas deverá ser realizado por
            boleto bancário. No caso do pagamento via boleto, ele poderá ser
            efetuado em qualquer agência (física ou virtual) bancária, casa
            lotérica ou agência dos Correios, obedecendo aos critérios
            estabelecidos nesses correspondentes bancários. O prazo de
            vencimento do boleto é dado automaticamente pela plataforma do
            Concurso e não poderá sofrer alterações.
          </small>
          <br />
          <br />
          <small>
            10.5. O pedido de medalhas só será confirmado após a confirmação do
            pagamento. No caso do boleto bancário, após o processamento pelo
            sistema bancário e a confirmação do pagamento dentro do prazo
            estabelecido no próprio boleto.
          </small>
          <br />
          <br />
          <small>
            10.6. O prazo para envio das medalhas dependerá da data em que foi
            feito o pagamento. Os envios acontecem em lotes pré-estabelecidos e
            as datas estarão disponíveis e visíveis na tela do site no momento
            de realização do pedido.
          </small>
          <br />
          <br />
          <small>
            10.7. A Nota Fiscal de Produto (DANFE) será emitida após a
            confirmação do pagamento do pedido. Assim que emitida, a Nota Fiscal
            será enviada por e-mail para o(s) endereço(s) cadastrado(s) pela
            escola durante a inscrição no Concurso Canguru e, em via física,
            junto com o pacote de medalhas. Não será possível a emissão de DANFE
            antes da confirmação do pagamento pela instituição bancária.
          </small>
          <br />
          <br />
          <small>
            10.8. Caso a escola tenha optado pelo modelo de inscrição Pacote
            Personalizado (ANEXO I), os itens adquiridos (publicações e placas
            de participação) serão enviados juntamente com o pacote de medalhas.
            Para as escolas que optaram pelo Pacote Personalizado e não
            adquiriram o pacote de medalhas durante o período de vendas, o envio
            dos itens será realizado após o encerramento das vendas das
            medalhas.
          </small>
          <br />
          <br />
          <small>
            10.8.1. Reforçamos que, conforme o item 10.2, a escola é responsável
            por informar corretamente todos os dados de endereço para envio,
            além dos contatos de e-mail e telefone, no momento do pedido. A
            organização do Concurso Canguru de Matemática Brasil não se
            responsabiliza pela não entrega das medalhas em caso de informações
            incorretas ou incompletas. Caso seja necessário um reenvio, a escola
            deverá arcar com o custo do frete. Portanto, solicitamos especial
            atenção à precisão das informações de endereço.
          </small>
          <br />
          <br />
          <small>
            10.9. As escolas participantes terão acesso aos Certificados
            digitais de Participação de todos os estudantes que realizaram a
            prova e aos Certificados de Premiação digital dos estudantes
            medalhistas. É de responsabilidade da escola a impressão e entrega
            dos Certificados aos seus estudantes e familiares. O Responsável
            pela escola no Concurso Canguru também poderá emitir, via
            plataforma, Certificados de Participação digital para os
            professores, coordenadores, diretores que foram cadastrados para
            organizar o Concurso em suas respectivas escolas, aos estudantes que
            participaram do Concurso e os Certificados de Premiação digital para
            os estudantes medalhistas.
          </small>
          <br />
          <br />
          <small>
            10.10. Os certificados e medalhas são obrigatoriamente emitidos ou
            produzidos pela organização do Concurso Canguru de Matemática
            Brasil. Qualquer emissão ou produção não oficial desses itens será
            penalizada com a exclusão da participação da escola (principal e
            coligadas, se for o caso).
          </small>
          <br />
          <br />
          <small>
            10.11. O início e o término do período de compra de medalhas estão
            definidos no Calendário/Cronograma Oficial do Concurso Canguru. As
            escolas serão notificadas sobre o fim do período de compra por meio
            de e-mail enviado aos endereços cadastrados.
          </small>
        </li>
        <li id="regras">
          <p>
            11. Declaração sobre as regras e normas de participação e
            responsabilidades
          </p>
          <small>
            11.1. É imprescindível que o(s) responsável(is) pela organização do
            Canguru em cada escola leia(m) e cumpra(m) rigorosamente as
            cláusulas desse regulamento, em cada estágio da participação. A
            Organização do Concurso Canguru de Matemática Brasil não fará
            qualquer concessão contrária a essas regras, quaisquer que sejam as
            alegações dos solicitantes.
          </small>
        </li>
        <li id="direitos-autorais">
          <p>11. Direitos autorais</p>
          <small>
            12.1. São reservados à Associação Canguru sem Fronteiras (
            <i>AKSF – Kangourou Sans Frontières</i>) o uso da marca{" "}
            <i>Kangourou des Mathemátiques</i> na França, ou o equivalente em
            outros países, como Canguru de Matemática Brasil, os conteúdos
            produzidos, tais como provas, livros, livretos, produtos
            educacionais, certificados e medalhas.
          </small>
          <br />
          <br />
          <small>
            12.2. As questões das provas podem ser utilizadas dentro do âmbito
            escolar, desde que acompanhadas da indicação da fonte: Canguru de
            Matemática Brasil. Não é autorizado o uso e a comercialização dos
            conteúdos Canguru sem autorização prévia e expressa dos
            Organizadores do Concurso.
          </small>
        </li>
        <br />
        <br />
        <li>
          <p>ANEXO I</p>
          <p>MODELOS DE INSCRIÇÃO E VALORES</p>
          <br />
          <p>PACOTE PADRÃO</p>
          <ul id="list">
            <li>
              &bull;
              <small>
                Acesso online ao cartaz de divulgação do Concurso com
                configurações para impressão.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Nova Área do Estudante: com quiz exclusivo e uma trilha de
                vídeos com resoluções detalhadas das provas anteriores!
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Download de provas e de folhas de respostas (as folhas de
                respostas serão identificadas com o nome da escola).
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Inserção das respostas dos estudantes pelo preenchimento de
                formulário via planilha Excel.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Acesso aos resultados de todos os estudantes da própria
                instituição de ensino que realizaram a prova.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Possibilidade de compra de medalhas para os estudantes premiados
                (ouro, prata, bronze e honra ao mérito). Para os estudantes que
                não foram premiados, é possível adquirir a medalha de
                participação.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Acesso aos certificados online de premiação e participação para
                todos os estudantes que realizaram as provas. Estes certificados
                contêm o nome dos estudantes e sua classificação, podendo ser
                impressos pela escola.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Acesso aos certificados online de participação para os
                Coordenadores Canguru cadastrados pela escola.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Sumário executivo, personalizado, contendo dados sobre o
                desempenho da escola no Concurso, por nível de prova, nível de
                dificuldade e eixo de conteúdo. Neste sumário, serão analisados
                os resultados da escola por nível de prova, indicando
                porcentagem de acerto de questões fáceis, médias e difíceis, e
                indicando o nível de acerto por eixo (álgebra, geometria,
                números e lógica). Os resultados serão analisados sem
                estabelecer comparações com outras escolas. Com a participação
                da escola nas próximas edições do Concurso Canguru, será
                possível estabelecer uma série histórica identificando o
                percurso da escola no Concurso.
                <br />
                Para acessar um modelo de sumário,
                <a
                  href="https://canguru-de-matematica.s3.us-east-1.amazonaws.com/Relatorio_Escola.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b> clique aqui</b>
                </a>
                .
              </small>
            </li>
            <br />
            <li>
              <p>
                VALOR: R$ 149,00 para escolas públicas e R$ 399,00 para escolas
                particulares.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <p>PACOTE PERSONALIZADO</p>
          <p>PADRÃO MAIS</p>
          <ul id="list">
            <li>
              &bull;
              <small>
                1 placa de participação da escola no Concurso de 2025.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                1 publicação Canguru, em formato revista, impressa e colorida,
                contendo as questões de 2025 com suas respectivas resoluções
                comentadas.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Nova Área do Estudante: agora com quiz exclusivo, com resultados
                visualizados pela escola, e uma trilha completa de vídeos com
                resolução detalhada das provas anteriores! Tudo para
                potencializar o aprendizado e apoiar o desenvolvimento do
                estudante.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Relatório de desempenho individual dos estudantes que realizaram
                a prova onde será analisado o índice de acerto das questões
                fáceis, médias e difíceis do estudante, o índice de acerto/erro
                por eixo (álgebra, geometria, números e lógica) e sua posição
                relativa aos demais estudantes de seu nível de prova, em sua
                escola (posicionamento em quartil).
                <br />
                <a
                  href="https://canguru-de-matematica.s3.us-east-1.amazonaws.com/Relatorio_Aluno.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Clique aqui</b>
                </a>{" "}
                para acessar o modelo.
              </small>
              <br />
            </li>
            <br />
            <li>
              <p>
                VALOR: R$ 343,00 para escolas públicas e R$ 787,00 para escolas
                particulares.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <p>CONCURSO + CANGURU EXPERIENCE:</p>
          <ul id="list">
            <li>
              &bull;
              <small>Canguru com você o ano inteiro!</small>
              <br />
            </li>
            <li>
              &bull;
              <small>Planos de Aula exclusivos do Canguru.</small>
              <br />
            </li>
            <li>
              &bull;
              <small>Atividades de Sondagem para uso em sala de aula.</small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Vídeos de formação continuada sobre temas da Matemática para
                Professores.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Trilhas formativas com diferentes conteúdos e materiais do
                Canguru.
              </small>
              <br />
            </li>
            <li>
              &bull;
              <small>
                Saiba mais{" "}
                <b>
                  <a
                    href="https://experience.cangurudematematicabrasil.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    clicando aqui.
                  </a>
                </b>
              </small>
              <br />
            </li>
            <br />
            <li>
              <p>
                VALOR: R$ 749,00 para escolas públicas e R$ 1599,00 para escolas
                particulares.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Regulamento;
