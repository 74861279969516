import React from "react";
import Banner from "../../components/banner/Banner";
import Sobre from "../../components/sobre/Sobre";
import Infos from "../../components/infos/Infos";
import Inscricoes from "../../components/inscricoes/Inscricoes";
import Depoimentos from "../../components/depoimentos/Depoimentos";
import Experience from "../../components/experience/Experience";

const Home = () => {
  return (
    <>
      <Banner />
      <Sobre />
      <Infos />
      <Inscricoes />
      <Experience />
      <Depoimentos />
    </>
  );
};

export default Home;
