import React from "react";

const Provas2023 = () => {
  return (
    <div className="container provas2023-container" id="provas-2023">
      <div className="provas-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font>{" "}
          <a href="/provas-anteriores">Provas Anteriores</a>{" "}
          <font color="#141414">/</font> 2024
        </p>
      </div>
      <div className="provas-content">
        <div className="provas-title">
          <p>Provas e Resoluções 2024</p>
          <h5>Online e Presencial</h5>
        </div>
        <div className="btn btn-corte">
          <a
            href="https://alunoprovas.s3.amazonaws.com/arquivosescola/outros/2024/NOTAS_DE_CORTE_2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nota de corte
          </a>
        </div>
        <div className="btn btn-gabarito">
          <a
            href="https://alunoprovas.s3.amazonaws.com/arquivosescola/outros/2024/GABARITO_CANGURU_2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gabarito
          </a>
        </div>
        <div className="provas2023-buttons">
          <div className="provas-p">
            <div className="provas-txt">
              <p>Prova Nível P (Pre-Ecolier) | </p>
              <small>3º e 4º anos do EFI</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaP_PT.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaP_EN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
            </div>
          </div>
          <div className="provas-e">
            <div className="provas-txt">
              <p>Prova Nível E (Ecolier) | </p>
              <small>5º e 6º anos do EFI e EFII, respectivamente</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaE_PT.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaE_EN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
            </div>
          </div>
          <div className="provas-b">
            <div className="provas-txt">
              <p>Prova Nível B (Benjamin) | </p>
              <small>7º e 8º anos do EFII</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaB_PT.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaB_EN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
            </div>
          </div>
          <div className="provas-c">
            <div className="provas-txt">
              <p>Prova Nível C (Cadet) |</p>
              <small>9º ano do EFII</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaC_PT.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaC_EN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
            </div>
          </div>
          <div className="provas-j">
            <div className="provas-txt">
              <p>Prova Nível J (Junior) | </p>
              <small>1º e 2º anos do EM</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaJ_PT.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaJ_EN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
            </div>
          </div>
          <div className="provas-s">
            <div className="provas-txt">
              <p>Prova Nível S (Student) | </p>
              <small>3º ano do EM</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaS_PT.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://alunoprovas.s3.amazonaws.com/arquivosescola/prova/2024/PDFs/ProvaS_EN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provas2023;
