import React from "react";
import "./experience.css";

import MOCKUP from "../../assets/img/experience/mockup.png";

const Experience = () => {
  return (
    <section id="experience">
      <div className="container experience-container">
        <div className="experience-top">
          <h4>Canguru Experience!</h4>
        </div>

        <div className="experience-mid">
          <div className="experience-mid-txt">
            <h5>Canguru de Matemática na Sala de Aula o ano todo!</h5>
            <p>Canguru Experience</p>
            <small>
              CanguruEXP é uma iniciativa do Canguru de Matemática Brasil que
              amplia e aprofunda a participação da escola para além do período
              de realização das provas do Concurso.
            </small>
            <br />
            <br />
            <small>
              <ul>
                O que oferecemos no Canguru Experience:
                <li>Jornada de Preparação para o Concurso</li>
                <li>Trilhas de aprendizagem</li>
                <li>Sondagens</li>
                <li>Aula Canguru (roteiros para sala de aula</li>
                <li>Notícias exclusivas da nossa Comunidade Internacional</li>
              </ul>
            </small>
          </div>
          <div className="experience-mid-img">
            <img
              src={MOCKUP}
              alt="simulação do canguru experience em um notebook"
            />
          </div>
        </div>

        <div className="experience-bottom">
          <p>Cadastro dos Professores</p>
          <small>
            Para realizar o cadastro, acesse o menu superior "Cadastro do
            Professor" e forneça o nome completo e o e-mail do professor.
            <br />
            Após o cadastro, os professores receberão um e-mail para validar e
            acessar a plataforma.
            <br />
            Lembramos que a plataforma está disponível no site do Canguru
            Experience, no menu superior "Plataforma Experience".
          </small>
        </div>
      </div>
    </section>
  );
};

export default Experience;
