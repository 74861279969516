import React, { useState, useEffect } from "react";
import "./banner.css";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

import MEDALHA from "../../assets/img/banner/menino-medalha.png";
import CERTIFICADO from "../../assets/img/banner/menina-certificado.png";

const Banner = () => {
  const banners = ["banner1", "banner2"];
  const [currentBanner, setCurrentBanner] = useState(0);

  const nextBanner = () => {
    setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
  };

  const prevBanner = () => {
    setCurrentBanner(
      (prevBanner) => (prevBanner - 1 + banners.length) % banners.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextBanner, 3500);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [currentBanner]);

  return (
    <div id="banner">
      {currentBanner === 0 && (
        <div className="container banner-container banner1">
          <div className="slide-btn1" onClick={prevBanner}>
            <PiCaretLeftBold size={20} />
          </div>
          <div className="banner-txt1">
            <h2>
              17<span>ª</span> edição do Concurso Internacional Canguru de
              Matemática Brasil
            </h2>
            <p>Matemática Divertida, Diferente e Desafiadora</p>
            <small>
              Para todos os estudantes do 3º ano do Fundamental I até o 3º ano
              do Ensino Médio.
            </small>
            <small id="negrito">Provas: março de 2025</small>

            <div className="btn banner-btn1">
              {/* <p>Em Breve</p> */}
              <a
                href="https://gestao.upmat.com.br/sales/kangaroo"
                target="_blank"
                rel="noopener noreferrer"
              >
                Inscrever-se
              </a>
            </div>
          </div>

          <div className="banner-img1">
            <img src={MEDALHA} alt="Canguru e menino com mochila" />
          </div>

          <div className="slide-btn2" onClick={nextBanner}>
            <PiCaretRightBold size={20} />
          </div>
        </div>
      )}

      {currentBanner === 1 && (
        <div className="container banner-container banner2">
          <div className="slide-btn1" onClick={prevBanner}>
            <PiCaretLeftBold size={20} />
          </div>
          <div className="banner-txt2">
            <h2>Seja um Patrocinador!</h2>
            <p>CANGURU DE MATEMÁTICA</p>
            <small>
              Queremos desmistificar a <b>Matemática</b> e inspirar a paixão
              pelo aprendizado no maior número possível de alunos.
            </small>

            <div className="btn banner-btn2">
              <a
                href="https://2024.cangurudematematicabrasil.com.br/quero-apoiar"
                target="_blank"
                rel="noopener noreferrer"
              >
                Quero Apoiar
              </a>
            </div>
          </div>

          <div className="banner-img2">
            <img src={CERTIFICADO} alt="Canguru e menina" />
          </div>

          <div className="slide-btn2" onClick={nextBanner}>
            <PiCaretRightBold size={20} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
